import { defaultSoftware } from './default-software';
import { sharedProdAuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultSoftware,
  ...sharedProdAuEnv,
  environment: 'production',
  apiUrl: 'https://api.admin.au.eucalyptus.vc',
  restApiUrl: 'https://admin-api2.skin.software',
  assetsUrl: 'https://assets.skin.software',
  logoUrl: 'https://assets.skin.software/software-logo.svg',
  shopifyHostname: 'skin-software.myshopify.com',
  userAppUrl: 'https://app.skin.software',
  doctorsAppUrl: 'https://doctors.skin.software',
  googleOAuthClientId:
    '966685249037-gin9l5mhlahpk6eud426k1fu32ksnvm5.apps.googleusercontent.com',
  quizAdminUrl: 'https://quiz-admin.skin.software',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee5',
};
